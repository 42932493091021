exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-campuses-index-js": () => import("./../../../src/pages/about/campuses/index.js" /* webpackChunkName: "component---src-pages-about-campuses-index-js" */),
  "component---src-pages-about-csr-begreener-js": () => import("./../../../src/pages/about/csr/begreener.js" /* webpackChunkName: "component---src-pages-about-csr-begreener-js" */),
  "component---src-pages-about-csr-behappier-js": () => import("./../../../src/pages/about/csr/behappier.js" /* webpackChunkName: "component---src-pages-about-csr-behappier-js" */),
  "component---src-pages-about-csr-bekinder-js": () => import("./../../../src/pages/about/csr/bekinder.js" /* webpackChunkName: "component---src-pages-about-csr-bekinder-js" */),
  "component---src-pages-about-csr-index-js": () => import("./../../../src/pages/about/csr/index.js" /* webpackChunkName: "component---src-pages-about-csr-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-sustainability-index-js": () => import("./../../../src/pages/about/sustainability/index.js" /* webpackChunkName: "component---src-pages-about-sustainability-index-js" */),
  "component---src-pages-about-triumphs-index-js": () => import("./../../../src/pages/about/triumphs/index.js" /* webpackChunkName: "component---src-pages-about-triumphs-index-js" */),
  "component---src-pages-about-why-tdcx-go-further-index-js": () => import("./../../../src/pages/about/why-tdcx/go-further/index.js" /* webpackChunkName: "component---src-pages-about-why-tdcx-go-further-index-js" */),
  "component---src-pages-about-why-tdcx-index-js": () => import("./../../../src/pages/about/why-tdcx/index.js" /* webpackChunkName: "component---src-pages-about-why-tdcx-index-js" */),
  "component---src-pages-about-why-tdcx-perform-better-index-js": () => import("./../../../src/pages/about/why-tdcx/perform-better/index.js" /* webpackChunkName: "component---src-pages-about-why-tdcx-perform-better-index-js" */),
  "component---src-pages-about-why-tdcx-scale-faster-index-js": () => import("./../../../src/pages/about/why-tdcx/scale-faster/index.js" /* webpackChunkName: "component---src-pages-about-why-tdcx-scale-faster-index-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-jobs-index-js": () => import("./../../../src/pages/careers/jobs/index.js" /* webpackChunkName: "component---src-pages-careers-jobs-index-js" */),
  "component---src-pages-careers-life-at-tdcx-index-js": () => import("./../../../src/pages/careers/life-at-tdcx/index.js" /* webpackChunkName: "component---src-pages-careers-life-at-tdcx-index-js" */),
  "component---src-pages-careers-tech-careers-index-js": () => import("./../../../src/pages/careers/tech-careers/index.js" /* webpackChunkName: "component---src-pages-careers-tech-careers-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-automotive-and-mobility-index-js": () => import("./../../../src/pages/industries/automotive-and-mobility/index.js" /* webpackChunkName: "component---src-pages-industries-automotive-and-mobility-index-js" */),
  "component---src-pages-industries-digital-advertising-index-js": () => import("./../../../src/pages/industries/digital-advertising/index.js" /* webpackChunkName: "component---src-pages-industries-digital-advertising-index-js" */),
  "component---src-pages-industries-e-commerce-and-retail-index-js": () => import("./../../../src/pages/industries/e-commerce-and-retail/index.js" /* webpackChunkName: "component---src-pages-industries-e-commerce-and-retail-index-js" */),
  "component---src-pages-industries-fintech-and-bfsi-index-js": () => import("./../../../src/pages/industries/fintech-and-bfsi/index.js" /* webpackChunkName: "component---src-pages-industries-fintech-and-bfsi-index-js" */),
  "component---src-pages-industries-gaming-and-entertainment-index-js": () => import("./../../../src/pages/industries/gaming-and-entertainment/index.js" /* webpackChunkName: "component---src-pages-industries-gaming-and-entertainment-index-js" */),
  "component---src-pages-industries-healthtech-index-js": () => import("./../../../src/pages/industries/healthtech/index.js" /* webpackChunkName: "component---src-pages-industries-healthtech-index-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-technology-index-js": () => import("./../../../src/pages/industries/technology/index.js" /* webpackChunkName: "component---src-pages-industries-technology-index-js" */),
  "component---src-pages-industries-travel-and-hospitality-index-js": () => import("./../../../src/pages/industries/travel-and-hospitality/index.js" /* webpackChunkName: "component---src-pages-industries-travel-and-hospitality-index-js" */),
  "component---src-pages-intuitreport-index-js": () => import("./../../../src/pages/intuitreport/index.js" /* webpackChunkName: "component---src-pages-intuitreport-index-js" */),
  "component---src-pages-isgreport-index-js": () => import("./../../../src/pages/isgreport/index.js" /* webpackChunkName: "component---src-pages-isgreport-index-js" */),
  "component---src-pages-media-center-events-cx-ecommerce-index-js": () => import("./../../../src/pages/media-center/events/cx-ecommerce/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-ecommerce-index-js" */),
  "component---src-pages-media-center-events-cx-fintech-index-js": () => import("./../../../src/pages/media-center/events/cx-fintech/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-fintech-index-js" */),
  "component---src-pages-media-center-events-cx-fintech-industry-index-js": () => import("./../../../src/pages/media-center/events/cx-fintech-industry/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-fintech-industry-index-js" */),
  "component---src-pages-media-center-events-cx-gaming-index-js": () => import("./../../../src/pages/media-center/events/cx-gaming/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-gaming-index-js" */),
  "component---src-pages-media-center-events-cx-talks-ecommerce-industry-meetup-index-js": () => import("./../../../src/pages/media-center/events/cx-talks-ecommerce-industry-meetup/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-talks-ecommerce-industry-meetup-index-js" */),
  "component---src-pages-media-center-events-cx-talks-gaming-industry-meetup-invite-index-js": () => import("./../../../src/pages/media-center/events/cx-talks-gaming-industry-meetup-invite/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-talks-gaming-industry-meetup-invite-index-js" */),
  "component---src-pages-media-center-events-cx-talks-index-js": () => import("./../../../src/pages/media-center/events/cx-talks/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-talks-index-js" */),
  "component---src-pages-media-center-events-cx-talks-tech-industry-meetup-index-js": () => import("./../../../src/pages/media-center/events/cx-talks-tech-industry-meetup/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-talks-tech-industry-meetup-index-js" */),
  "component---src-pages-media-center-events-cx-tech-index-js": () => import("./../../../src/pages/media-center/events/cx-tech/index.js" /* webpackChunkName: "component---src-pages-media-center-events-cx-tech-index-js" */),
  "component---src-pages-media-center-events-index-js": () => import("./../../../src/pages/media-center/events/index.js" /* webpackChunkName: "component---src-pages-media-center-events-index-js" */),
  "component---src-pages-media-center-events-powerful-cx-in-the-age-of-ai-index-js": () => import("./../../../src/pages/media-center/events/powerful-cx-in-the-age-of-ai/index.js" /* webpackChunkName: "component---src-pages-media-center-events-powerful-cx-in-the-age-of-ai-index-js" */),
  "component---src-pages-media-center-events-travelandhospitality-index-js": () => import("./../../../src/pages/media-center/events/travelandhospitality/index.js" /* webpackChunkName: "component---src-pages-media-center-events-travelandhospitality-index-js" */),
  "component---src-pages-media-center-financial-news-index-js": () => import("./../../../src/pages/media-center/financial_news/index.js" /* webpackChunkName: "component---src-pages-media-center-financial-news-index-js" */),
  "component---src-pages-media-center-in-the-news-index-js": () => import("./../../../src/pages/media-center/in_the_news/index.js" /* webpackChunkName: "component---src-pages-media-center-in-the-news-index-js" */),
  "component---src-pages-media-center-index-js": () => import("./../../../src/pages/media-center/index.js" /* webpackChunkName: "component---src-pages-media-center-index-js" */),
  "component---src-pages-media-center-insights-blogs-index-js": () => import("./../../../src/pages/media-center/insights/blogs/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-blogs-index-js" */),
  "component---src-pages-media-center-insights-case-studies-consumer-electronics-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/consumer-electronics/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-consumer-electronics-index-js" */),
  "component---src-pages-media-center-insights-case-studies-digital-advertising-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/digital-advertising/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-digital-advertising-index-js" */),
  "component---src-pages-media-center-insights-case-studies-e-commerce-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/e-commerce/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-e-commerce-index-js" */),
  "component---src-pages-media-center-insights-case-studies-fintech-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/fintech/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-fintech-index-js" */),
  "component---src-pages-media-center-insights-case-studies-food-and-beverage-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/food-and-beverage/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-food-and-beverage-index-js" */),
  "component---src-pages-media-center-insights-case-studies-gaming-experience-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/gaming-experience/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-gaming-experience-index-js" */),
  "component---src-pages-media-center-insights-case-studies-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-index-js" */),
  "component---src-pages-media-center-insights-case-studies-social-media-building-safer-social-networks-through-trust-safety-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/social-media/building-safer-social-networks-through-trust-&-safety/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-social-media-building-safer-social-networks-through-trust-safety-index-js" */),
  "component---src-pages-media-center-insights-case-studies-social-media-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/social-media/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-social-media-index-js" */),
  "component---src-pages-media-center-insights-case-studies-social-media-scaling-trust-and-safety-in-southeast-asia-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/social-media/scaling-trust-and-safety-in-southeast-asia/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-social-media-scaling-trust-and-safety-in-southeast-asia-index-js" */),
  "component---src-pages-media-center-insights-case-studies-travel-airline-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/travel-airline/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-travel-airline-index-js" */),
  "component---src-pages-media-center-insights-case-studies-travel-and-hospitality-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/travel-and-hospitality/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-travel-and-hospitality-index-js" */),
  "component---src-pages-media-center-insights-case-studies-travel-hospitality-experts-set-sail-with-trust-safety-index-js": () => import("./../../../src/pages/media-center/insights/case-studies/travel/hospitality-experts-set-sail-with-trust-&-safety/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-case-studies-travel-hospitality-experts-set-sail-with-trust-safety-index-js" */),
  "component---src-pages-media-center-insights-e-books-index-js": () => import("./../../../src/pages/media-center/insights/e_books/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-e-books-index-js" */),
  "component---src-pages-media-center-insights-index-js": () => import("./../../../src/pages/media-center/insights/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-index-js" */),
  "component---src-pages-media-center-insights-webinars-index-js": () => import("./../../../src/pages/media-center/insights/webinars/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-webinars-index-js" */),
  "component---src-pages-media-center-insights-white-papers-index-js": () => import("./../../../src/pages/media-center/insights/white_papers/index.js" /* webpackChunkName: "component---src-pages-media-center-insights-white-papers-index-js" */),
  "component---src-pages-media-center-press-releases-index-js": () => import("./../../../src/pages/media-center/press_releases/index.js" /* webpackChunkName: "component---src-pages-media-center-press-releases-index-js" */),
  "component---src-pages-policies-bcr-js": () => import("./../../../src/pages/policies/bcr.js" /* webpackChunkName: "component---src-pages-policies-bcr-js" */),
  "component---src-pages-policies-bcr-sg-js": () => import("./../../../src/pages/policies/bcr-sg.js" /* webpackChunkName: "component---src-pages-policies-bcr-sg-js" */),
  "component---src-pages-policies-cookies-js": () => import("./../../../src/pages/policies/cookies.js" /* webpackChunkName: "component---src-pages-policies-cookies-js" */),
  "component---src-pages-policies-it-js": () => import("./../../../src/pages/policies/it.js" /* webpackChunkName: "component---src-pages-policies-it-js" */),
  "component---src-pages-policies-privacy-js": () => import("./../../../src/pages/policies/privacy.js" /* webpackChunkName: "component---src-pages-policies-privacy-js" */),
  "component---src-pages-policies-privacy-sg-js": () => import("./../../../src/pages/policies/privacy-sg.js" /* webpackChunkName: "component---src-pages-policies-privacy-sg-js" */),
  "component---src-pages-policies-terms-of-use-js": () => import("./../../../src/pages/policies/terms-of-use.js" /* webpackChunkName: "component---src-pages-policies-terms-of-use-js" */),
  "component---src-pages-policies-vendors-js": () => import("./../../../src/pages/policies/vendors.js" /* webpackChunkName: "component---src-pages-policies-vendors-js" */),
  "component---src-pages-services-ai-services-index-js": () => import("./../../../src/pages/services/ai-services/index.js" /* webpackChunkName: "component---src-pages-services-ai-services-index-js" */),
  "component---src-pages-services-customer-service-index-js": () => import("./../../../src/pages/services/customer-service/index.js" /* webpackChunkName: "component---src-pages-services-customer-service-index-js" */),
  "component---src-pages-services-cx-consulting-js": () => import("./../../../src/pages/services/cx-consulting.js" /* webpackChunkName: "component---src-pages-services-cx-consulting-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-sales-index-js": () => import("./../../../src/pages/services/sales/index.js" /* webpackChunkName: "component---src-pages-services-sales-index-js" */),
  "component---src-pages-services-technical-support-index-js": () => import("./../../../src/pages/services/technical-support/index.js" /* webpackChunkName: "component---src-pages-services-technical-support-index-js" */),
  "component---src-pages-services-trust-and-safety-index-js": () => import("./../../../src/pages/services/trust-and-safety/index.js" /* webpackChunkName: "component---src-pages-services-trust-and-safety-index-js" */),
  "component---src-pages-services-trust-and-safety-read-more-js": () => import("./../../../src/pages/services/trust-and-safety/read-more.js" /* webpackChunkName: "component---src-pages-services-trust-and-safety-read-more-js" */),
  "component---src-pages-services-work-at-home-js": () => import("./../../../src/pages/services/work-at-home.js" /* webpackChunkName: "component---src-pages-services-work-at-home-js" */),
  "component---src-templates-management-team-details-js": () => import("./../../../src/templates/managementTeamDetails.js" /* webpackChunkName: "component---src-templates-management-team-details-js" */),
  "component---src-templates-management-team-js": () => import("./../../../src/templates/managementTeam.js" /* webpackChunkName: "component---src-templates-management-team-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/newsDetails.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-superstar-details-js": () => import("./../../../src/templates/superstarDetails.js" /* webpackChunkName: "component---src-templates-superstar-details-js" */),
  "component---src-templates-superstars-js": () => import("./../../../src/templates/superstars.js" /* webpackChunkName: "component---src-templates-superstars-js" */)
}

